import React from 'react';

import cn from 'classnames';
import Image from 'next/legacy/image';

import { Icon } from '@components/index'
import { THEME } from '@constants/base';
import { Select } from '@element/index'
import I18n from '@utils/i18n';
import UserCountriesData from '@utils/offlineData/countries';

import countryStyle from './index.module.css'

export const Flag = ({ nationalFlag, className, flagIsImage, imgW, imgH }) => {
  if (flagIsImage) {
    return <span className={cn(countryStyle.itemFlag, className)}>
      <Image alt='kikiotrade' width={imgW || 16} height={imgH || 12} src={require(`@images/svgIcon/flags/${nationalFlag}.png`)} />
      </span>
  }
  return <Icon name={nationalFlag} className={cn(countryStyle.itemFlag, className)} />
}

export default function CountrySelect2 (props) {
  const {
    value: { telephoneCode: code, id }, onChange, renderValue: propsRenderValue,
    selectBtnCalssName, theme, whiteList, searchListScrollBottomTip
  } = props;
  const formatOptions = (UserCountriesData) => {
    let userCountriesData = UserCountriesData
    if (whiteList) {
      userCountriesData = UserCountriesData.filter(i => whiteList.indexOf(i.id) > -1)
    }
    return userCountriesData?.map(item => {
      return {
        ...item,
        label: (<>
          <Flag nationalFlag={item.nationalFlag} flagIsImage={item.flagIsImage} />
          <span className={countryStyle.itemName}>{I18n.locale === 'zh-CN' ? item.displayName : item.commonName}</span>
          <span className={countryStyle.itemCode}>+{item.telephoneCode}</span>
        </>),
        value: `${item.telephoneCode}_${item.id}`
      }
    })
  }
  const renderValue = (params) => {
    return (<>
    <Flag nationalFlag={params?.nationalFlag} flagIsImage={params?.flagIsImage}
     imgW={28} imgH={21} className={countryStyle.nationalFlag} />
    <span className={countryStyle.telephoneCode}>+{params?.telephoneCode}</span>
    </>)
  }
  return (
    <Select
      options={formatOptions(UserCountriesData)}
      onChange={(params) => {
        onChange({ id: params.id, telephoneCode: params.telephoneCode })
      }}
      searchListScrollBottomTip={searchListScrollBottomTip}
      search={{
        // placeholder: I18n.t('e3zMzAuM'),
        placeholder: I18n.t('search'),
        isClear: true, // 显示清空图标
        empty: I18n.t('not.found'),
        onChange: (value) => {
          const data = UserCountriesData.filter(item => {
            if (item.id.toLowerCase().includes(value.toLowerCase())) {
              return true
            }
            if (item.displayName.toLowerCase().includes(value.toLowerCase())) {
              return true
            }
            if (item.telephoneCode.toLowerCase().includes(value.toLowerCase())) {
              return true
            }
            return false
          })
          return formatOptions(data)
        }
      }}
      virtualListConfig={{
        height: 200,
        itemSize: 40
      }}
      theme={theme}
      value={`${code}_${id}`}
      optionWrapperClass={countryStyle.optionWrapperClass}
      className={cn(countryStyle.countrySelect, {
        [countryStyle.dark_countrySelect]: theme === THEME.DARK
      })}
      selectBtnCalssName={cn(countryStyle.btn, selectBtnCalssName, {
        [countryStyle.dark_btn]: theme === THEME.DARK
      })}
      align={{ offset: [0, 3] }}
      renderValue={propsRenderValue || renderValue}
      popoverClass={countryStyle.popover}
    />
  );
}

const countries = {
  A: [
    {
      chineseName: '',
      commonName: 'Afghanistan',
      displayName: '阿富汗',
      enable: true,
      formalName: '',
      id: 'AF',
      localName: '',
      nationalFlag: 'af',
      startChar: 'A',
      telephoneCode: '93'
    },
    {
      chineseName: '',
      commonName: 'Albania',
      displayName: '阿尔巴尼亚',
      enable: true,
      formalName: '',
      id: 'AL',
      localName: '',
      nationalFlag: 'al',
      startChar: 'A',
      telephoneCode: '355'
    },
    {
      chineseName: '',
      commonName: 'Algeria',
      displayName: '阿尔及利亚',
      enable: true,
      formalName: '',
      id: 'DZ',
      localName: '',
      nationalFlag: 'dz',
      startChar: 'A',
      telephoneCode: '213'
    },
    {
      chineseName: '',
      commonName: 'Andorra',
      displayName: '安道尔',
      enable: true,
      formalName: '',
      id: 'AD',
      localName: '',
      nationalFlag: 'ad',
      startChar: 'A',
      telephoneCode: '376'
    },
    {
      chineseName: '',
      commonName: 'Angola',
      displayName: '安哥拉',
      enable: true,
      formalName: '',
      id: 'AO',
      localName: '',
      nationalFlag: 'ao',
      startChar: 'A',
      telephoneCode: '244'
    },
    {
      chineseName: '',
      commonName: 'Anguilla',
      displayName: '安圭拉',
      enable: true,
      formalName: '',
      id: 'AI',
      localName: '',
      nationalFlag: 'ai',
      startChar: 'A',
      telephoneCode: '1264'
    },
    {
      chineseName: '',
      commonName: 'Antigua and Barbuda',
      displayName: '安提瓜和巴布达',
      enable: true,
      formalName: '',
      id: 'AG',
      localName: '',
      nationalFlag: 'ag',
      startChar: 'A',
      telephoneCode: '1268'
    },
    {
      chineseName: '',
      commonName: 'Argentina',
      displayName: '阿根廷',
      enable: true,
      formalName: '',
      id: 'AR',
      localName: '',
      nationalFlag: 'ar',
      startChar: 'A',
      telephoneCode: '54'
    },
    {
      chineseName: '',
      commonName: 'Armenia',
      displayName: '亚美尼亚',
      enable: true,
      formalName: '',
      id: 'AM',
      localName: '',
      nationalFlag: 'am',
      startChar: 'A',
      telephoneCode: '374'
    },
    {
      chineseName: '',
      commonName: 'Australia',
      displayName: '澳大利亚',
      enable: true,
      formalName: '',
      id: 'AU',
      localName: '',
      nationalFlag: 'au',
      startChar: 'A',
      telephoneCode: '61'
    },
    {
      chineseName: '',
      commonName: 'Austria',
      displayName: '奥地利',
      enable: true,
      formalName: '',
      id: 'AT',
      localName: '',
      nationalFlag: 'at',
      startChar: 'A',
      telephoneCode: '43'
    },
    {
      chineseName: '',
      commonName: 'Azerbaijan',
      displayName: '阿塞拜疆',
      enable: true,
      formalName: '',
      id: 'AZ',
      localName: '',
      nationalFlag: 'az',
      startChar: 'A',
      telephoneCode: '994'
    }
  ],
  B: [
    {
      chineseName: '',
      commonName: 'Bahamas',
      displayName: '巴哈马',
      enable: true,
      formalName: '',
      id: 'BS',
      localName: '',
      nationalFlag: 'bs',
      startChar: 'B',
      telephoneCode: '1242'
    },
    {
      chineseName: '',
      commonName: 'Bahrain',
      displayName: '巴林',
      enable: true,
      formalName: '',
      id: 'BH',
      localName: '',
      nationalFlag: 'bh',
      startChar: 'B',
      telephoneCode: '973'
    },
    {
      chineseName: '',
      commonName: 'Bangladesh',
      displayName: '孟加拉国',
      enable: true,
      formalName: '',
      id: 'BD',
      localName: '',
      nationalFlag: 'bd',
      startChar: 'B',
      telephoneCode: '880'
    },
    {
      chineseName: '',
      commonName: 'Barbados',
      displayName: '巴巴多斯',
      enable: true,
      formalName: '',
      id: 'BB',
      localName: '',
      nationalFlag: 'bb',
      startChar: 'B',
      telephoneCode: '1246'
    },
    {
      chineseName: '',
      commonName: 'Belarus',
      displayName: '白俄罗斯',
      enable: true,
      formalName: '',
      id: 'BY',
      localName: '',
      nationalFlag: 'by',
      startChar: 'B',
      telephoneCode: '375'
    },
    {
      chineseName: '',
      commonName: 'Belgium',
      displayName: '比利时',
      enable: true,
      formalName: '',
      id: 'BE',
      localName: '',
      nationalFlag: 'be',
      startChar: 'B',
      telephoneCode: '32'
    },
    {
      chineseName: '',
      commonName: 'Belize',
      displayName: '伯利兹',
      enable: true,
      formalName: '',
      id: 'BZ',
      localName: '',
      nationalFlag: 'bz',
      startChar: 'B',
      telephoneCode: '501'
    },
    {
      chineseName: '',
      commonName: 'Benin',
      displayName: '贝宁',
      enable: true,
      formalName: '',
      id: 'BJ',
      localName: '',
      nationalFlag: 'bj',
      startChar: 'B',
      telephoneCode: '229'
    },
    {
      chineseName: '',
      commonName: 'Bermuda',
      displayName: '百慕大',
      enable: true,
      formalName: '',
      id: 'BM',
      localName: '',
      nationalFlag: 'bm',
      startChar: 'B',
      telephoneCode: '1441'
    },
    {
      chineseName: '',
      commonName: 'Bhutan',
      displayName: '不丹',
      enable: true,
      formalName: '',
      id: 'BT',
      localName: '',
      nationalFlag: 'bt',
      startChar: 'B',
      telephoneCode: '975'
    },
    {
      chineseName: '',
      commonName: 'Bolivia',
      displayName: '玻利维亚（多民族国）',
      enable: true,
      formalName: '',
      id: 'BO',
      localName: '',
      nationalFlag: 'bo',
      startChar: 'B',
      telephoneCode: '591'
    },
    {
      chineseName: '',
      commonName: 'Bosnia and Herzegovina',
      displayName: '波斯尼亚和黑塞哥维那',
      enable: true,
      formalName: '',
      id: 'BA',
      localName: '',
      nationalFlag: 'ba',
      startChar: 'B',
      telephoneCode: '387'
    },
    {
      chineseName: '',
      commonName: 'Botswana',
      displayName: '博茨瓦纳',
      enable: true,
      formalName: '',
      id: 'BW',
      localName: '',
      nationalFlag: 'bw',
      startChar: 'B',
      telephoneCode: '267'
    },
    {
      chineseName: '',
      commonName: 'Brazil',
      displayName: '巴西',
      enable: true,
      formalName: '',
      id: 'BR',
      localName: '',
      nationalFlag: 'br',
      startChar: 'B',
      telephoneCode: '55'
    },
    {
      chineseName: '',
      commonName: 'British Virgin Islands',
      displayName: '维尔京群岛（英国）',
      enable: true,
      formalName: '',
      id: 'VG',
      localName: '',
      nationalFlag: 'vg',
      startChar: 'B',
      telephoneCode: '1284'
    },
    {
      chineseName: '',
      commonName: 'Brunei',
      displayName: '文莱达鲁萨兰国',
      enable: true,
      formalName: '',
      id: 'BN',
      localName: '',
      nationalFlag: 'bn',
      startChar: 'B',
      telephoneCode: '673'
    },
    {
      chineseName: '',
      commonName: 'Bulgaria',
      displayName: '保加利亚',
      enable: true,
      formalName: '',
      id: 'BG',
      localName: '',
      nationalFlag: 'bg',
      startChar: 'B',
      telephoneCode: '359'
    },
    {
      chineseName: '',
      commonName: 'Burkina Faso',
      displayName: '布基纳法索',
      enable: true,
      formalName: '',
      id: 'BF',
      localName: '',
      nationalFlag: 'bf',
      startChar: 'B',
      telephoneCode: '226'
    },
    {
      chineseName: '',
      commonName: 'Burundi',
      displayName: '布隆迪',
      enable: true,
      formalName: '',
      id: 'BI',
      localName: '',
      nationalFlag: 'bi',
      startChar: 'B',
      telephoneCode: '257'
    }
  ],
  C: [
    {
      chineseName: '',
      commonName: 'Cambodia',
      displayName: '柬埔寨',
      enable: true,
      formalName: '',
      id: 'KH',
      localName: '',
      nationalFlag: 'kh',
      startChar: 'C',
      telephoneCode: '855'
    },
    {
      chineseName: '',
      commonName: 'Cameroon',
      displayName: '喀麦隆',
      enable: true,
      formalName: '',
      id: 'CM',
      localName: '',
      nationalFlag: 'cm',
      startChar: 'C',
      telephoneCode: '237'
    },
    {
      chineseName: '',
      commonName: 'Canada',
      displayName: '加拿大',
      enable: true,
      formalName: '',
      id: 'CA',
      localName: '',
      nationalFlag: 'ca',
      startChar: 'C',
      telephoneCode: '1'
    },
    {
      chineseName: '',
      commonName: 'Cape Verde',
      displayName: '佛得角',
      enable: true,
      formalName: '',
      id: 'CV',
      localName: '',
      nationalFlag: 'cv',
      startChar: 'C',
      telephoneCode: '238'
    },
    {
      chineseName: '',
      commonName: 'Cayman Islands',
      displayName: '开曼群岛',
      enable: true,
      formalName: '',
      id: 'KY',
      localName: '',
      nationalFlag: 'ky',
      startChar: 'C',
      telephoneCode: '1345'
    },
    {
      chineseName: '',
      commonName: 'Central African Republic',
      displayName: '中非共和国',
      enable: true,
      formalName: '',
      id: 'CF',
      localName: '',
      nationalFlag: 'cf',
      startChar: 'C',
      telephoneCode: '236'
    },
    {
      chineseName: '',
      commonName: 'Chad',
      displayName: '乍得',
      enable: true,
      formalName: '',
      id: 'TD',
      localName: '',
      nationalFlag: 'td',
      startChar: 'C',
      telephoneCode: '235'
    },
    {
      chineseName: '',
      commonName: 'Chile',
      displayName: '智利',
      enable: true,
      formalName: '',
      id: 'CL',
      localName: '',
      nationalFlag: 'cl',
      startChar: 'C',
      telephoneCode: '56'
    },
    {
      chineseName: '',
      commonName: 'China',
      displayName: '中国',
      enable: true,
      formalName: '',
      id: 'CN',
      localName: '',
      nationalFlag: 'cn',
      startChar: 'C',
      telephoneCode: '86'
    },
    {
      chineseName: '',
      commonName: 'Colombia',
      displayName: '哥伦比亚',
      enable: true,
      formalName: '',
      id: 'CO',
      localName: '',
      nationalFlag: 'co',
      startChar: 'C',
      telephoneCode: '57'
    },
    {
      chineseName: '',
      commonName: 'Comoros',
      displayName: '科摩罗',
      enable: true,
      formalName: '',
      id: 'KM',
      localName: '',
      nationalFlag: 'km',
      startChar: 'C',
      telephoneCode: '269'
    },
    {
      chineseName: '',
      commonName: 'Congo, Republic',
      displayName: '刚果',
      enable: true,
      formalName: '',
      id: 'CG',
      localName: '',
      nationalFlag: 'cg',
      startChar: 'C',
      telephoneCode: '242'
    },
    {
      chineseName: '',
      commonName: 'Congo,Democratic Republic',
      displayName: '刚果（民主共和国）',
      enable: true,
      formalName: '',
      id: 'CD',
      localName: '',
      nationalFlag: 'cd',
      startChar: 'C',
      telephoneCode: '243'
    },
    {
      chineseName: '',
      commonName: 'Costa Rica',
      displayName: '哥斯达黎加',
      enable: true,
      formalName: '',
      id: 'CR',
      localName: '',
      nationalFlag: 'cr',
      startChar: 'C',
      telephoneCode: '506'
    },
    {
      chineseName: '',
      commonName: 'Cote d´Ivoire (Ivory Coast)',
      displayName: '科特迪瓦',
      enable: true,
      formalName: '',
      id: 'CI',
      localName: '',
      nationalFlag: 'ci',
      startChar: 'C',
      telephoneCode: '225'
    },
    {
      chineseName: '',
      commonName: 'Croatia',
      displayName: '克罗地亚',
      enable: true,
      formalName: '',
      id: 'HR',
      localName: '',
      nationalFlag: 'hr',
      startChar: 'C',
      telephoneCode: '385'
    },
    {
      chineseName: '',
      commonName: 'Cuba',
      displayName: '古巴',
      enable: true,
      formalName: '',
      id: 'CU',
      localName: '',
      nationalFlag: 'cu',
      startChar: 'C',
      telephoneCode: '53'
    },
    {
      chineseName: '',
      commonName: 'Curacao',
      displayName: '库拉索',
      enable: true,
      formalName: '',
      id: 'CW',
      localName: '',
      nationalFlag: 'cw',
      startChar: 'C',
      telephoneCode: '599'
    },
    {
      chineseName: '',
      commonName: 'Cyprus',
      displayName: '塞浦路斯',
      enable: true,
      formalName: '',
      id: 'CY',
      localName: '',
      nationalFlag: 'cy',
      startChar: 'C',
      telephoneCode: '357'
    },
    {
      chineseName: '',
      commonName: 'Czech Republic',
      displayName: '捷克',
      enable: true,
      formalName: '',
      id: 'CZ',
      localName: '',
      nationalFlag: 'cz',
      startChar: 'C',
      telephoneCode: '420'
    }
  ],
  D: [
    {
      chineseName: '',
      commonName: 'Denmark',
      displayName: '丹麦',
      enable: true,
      formalName: '',
      id: 'DK',
      localName: '',
      nationalFlag: 'dk',
      startChar: 'D',
      telephoneCode: '45'
    },
    {
      chineseName: '',
      commonName: 'Djibouti',
      displayName: '吉布提',
      enable: true,
      formalName: '',
      id: 'DJ',
      localName: '',
      nationalFlag: 'dj',
      startChar: 'D',
      telephoneCode: '253'
    },
    {
      chineseName: '',
      commonName: 'Dominica',
      displayName: '多米尼克',
      enable: true,
      formalName: '',
      id: 'DM',
      localName: '',
      nationalFlag: 'dm',
      startChar: 'D',
      telephoneCode: '1767'
    },
    {
      chineseName: '',
      commonName: 'Dominican Republic',
      displayName: '多米尼加共和国',
      enable: true,
      formalName: '',
      id: 'DO',
      localName: '',
      nationalFlag: 'do',
      startChar: 'D',
      telephoneCode: '1829',
      flagIsImage: true
    },
    {
      chineseName: '',
      commonName: 'Dominican Republic',
      displayName: '多米尼加共和国',
      enable: true,
      formalName: '',
      id: 'DO',
      localName: '',
      nationalFlag: 'do',
      startChar: 'D',
      telephoneCode: '1809',
      flagIsImage: true
    },
    {
      chineseName: '',
      commonName: 'Dominican Republic',
      displayName: '多米尼加共和国',
      enable: true,
      formalName: '',
      id: 'DO',
      localName: '',
      nationalFlag: 'do',
      startChar: 'D',
      telephoneCode: '1849',
      flagIsImage: true
    }
  ],
  E: [
    {
      chineseName: '',
      commonName: 'Ecuador',
      displayName: '厄瓜多尔',
      enable: true,
      formalName: '',
      id: 'EC',
      localName: '',
      nationalFlag: 'ec',
      startChar: 'E',
      telephoneCode: '593'
    },
    {
      chineseName: '',
      commonName: 'Egypt',
      displayName: '埃及',
      enable: true,
      formalName: '',
      id: 'EG',
      localName: '',
      nationalFlag: 'eg',
      startChar: 'E',
      telephoneCode: '20'
    },
    {
      chineseName: '',
      commonName: 'El Salvador',
      displayName: '萨尔瓦多',
      enable: true,
      formalName: '',
      id: 'SV',
      localName: '',
      nationalFlag: 'sv',
      startChar: 'E',
      telephoneCode: '503'
    },
    {
      chineseName: '',
      commonName: 'Equatorial Guinea',
      displayName: '赤道几内亚',
      enable: true,
      formalName: '',
      id: 'GQ',
      localName: '',
      nationalFlag: 'gq',
      startChar: 'E',
      telephoneCode: '240'
    },
    {
      chineseName: '',
      commonName: 'Eritrea',
      displayName: '厄立特里亚',
      enable: true,
      formalName: '',
      id: 'ER',
      localName: '',
      nationalFlag: 'er',
      startChar: 'E',
      telephoneCode: '291'
    },
    {
      chineseName: '',
      commonName: 'Estonia',
      displayName: '爱沙尼亚',
      enable: true,
      formalName: '',
      id: 'EE',
      localName: '',
      nationalFlag: 'ee',
      startChar: 'E',
      telephoneCode: '372'
    },
    {
      chineseName: '',
      commonName: 'Ethiopia',
      displayName: '埃塞俄比亚',
      enable: true,
      formalName: '',
      id: 'ET',
      localName: '',
      nationalFlag: 'et',
      startChar: 'E',
      telephoneCode: '251'
    }
  ],
  F: [
    {
      chineseName: '',
      commonName: 'Fiji',
      displayName: '斐济',
      enable: true,
      formalName: '',
      id: 'FJ',
      localName: '',
      nationalFlag: 'fj',
      startChar: 'F',
      telephoneCode: '679'
    },
    {
      chineseName: '',
      commonName: 'Finland',
      displayName: '芬兰',
      enable: true,
      formalName: '',
      id: 'FI',
      localName: '',
      nationalFlag: 'fi',
      startChar: 'F',
      telephoneCode: '358'
    },
    {
      chineseName: '',
      commonName: 'France',
      displayName: '法国',
      enable: true,
      formalName: '',
      id: 'FR',
      localName: '',
      nationalFlag: 'fr',
      startChar: 'F',
      telephoneCode: '33'
    }
  ],
  G: [
    {
      chineseName: '',
      commonName: 'Gabon',
      displayName: '加蓬',
      enable: true,
      formalName: '',
      id: 'GA',
      localName: '',
      nationalFlag: 'ga',
      startChar: 'G',
      telephoneCode: '241'
    },
    {
      chineseName: '',
      commonName: 'Gambia, The',
      displayName: '冈比亚',
      enable: true,
      formalName: '',
      id: 'GM',
      localName: '',
      nationalFlag: 'gm',
      startChar: 'G',
      telephoneCode: '220'
    },
    {
      chineseName: '',
      commonName: 'Georgia',
      displayName: '格鲁吉亚',
      enable: true,
      formalName: '',
      id: 'GE',
      localName: '',
      nationalFlag: 'ge',
      startChar: 'G',
      telephoneCode: '995'
    },
    {
      chineseName: '',
      commonName: 'Germany',
      displayName: '德国',
      enable: true,
      formalName: '',
      id: 'DE',
      localName: '',
      nationalFlag: 'de',
      startChar: 'G',
      telephoneCode: '49'
    },
    {
      chineseName: '',
      commonName: 'Ghana',
      displayName: '加纳',
      enable: true,
      formalName: '',
      id: 'GH',
      localName: '',
      nationalFlag: 'gh',
      startChar: 'G',
      telephoneCode: '233'
    },
    {
      chineseName: '',
      commonName: 'Greece',
      displayName: '希腊',
      enable: true,
      formalName: '',
      id: 'GR',
      localName: '',
      nationalFlag: 'gr',
      startChar: 'G',
      telephoneCode: '30'
    },
    {
      chineseName: '',
      commonName: 'Grenada',
      displayName: '格林纳达',
      enable: true,
      formalName: '',
      id: 'GD',
      localName: '',
      nationalFlag: 'gd',
      startChar: 'G',
      telephoneCode: '1473'
    },
    {
      chineseName: '',
      commonName: 'Guatemala',
      displayName: '危地马拉',
      enable: true,
      formalName: '',
      id: 'GT',
      localName: '',
      nationalFlag: 'gt',
      startChar: 'G',
      telephoneCode: '502'
    },
    {
      chineseName: '',
      commonName: 'Guinea',
      displayName: '几内亚',
      enable: true,
      formalName: '',
      id: 'GN',
      localName: '',
      nationalFlag: 'gn',
      startChar: 'G',
      telephoneCode: '224'
    },
    {
      chineseName: '',
      commonName: 'Guinea-Bissau',
      displayName: '几内亚比绍',
      enable: true,
      formalName: '',
      id: 'GW',
      localName: '',
      nationalFlag: 'gw',
      startChar: 'G',
      telephoneCode: '245'
    },
    {
      chineseName: '',
      commonName: 'Guyana',
      displayName: '圭亚那',
      enable: true,
      formalName: '',
      id: 'GY',
      localName: '',
      nationalFlag: 'gy',
      startChar: 'G',
      telephoneCode: '592'
    }
  ],
  H: [
    {
      chineseName: '',
      commonName: 'Haiti',
      displayName: '海地',
      enable: true,
      formalName: '',
      id: 'HT',
      localName: '',
      nationalFlag: 'ht',
      startChar: 'H',
      telephoneCode: '509'
    },
    {
      chineseName: '',
      commonName: 'Honduras',
      displayName: '洪都拉斯',
      enable: true,
      formalName: '',
      id: 'HN',
      localName: '',
      nationalFlag: 'hn',
      startChar: 'H',
      telephoneCode: '504'
    },
    {
      chineseName: '',
      commonName: 'Hong Kong',
      displayName: '香港,中国',
      enable: true,
      formalName: '',
      id: 'HK',
      localName: '',
      nationalFlag: 'hk',
      startChar: 'H',
      telephoneCode: '852'
    },
    {
      chineseName: '',
      commonName: 'Hungary',
      displayName: '匈牙利',
      enable: true,
      formalName: '',
      id: 'HU',
      localName: '',
      nationalFlag: 'hu',
      startChar: 'H',
      telephoneCode: '36'
    }
  ],
  I: [
    {
      chineseName: '',
      commonName: 'Iceland',
      displayName: '冰岛',
      enable: true,
      formalName: '',
      id: 'IS',
      localName: '',
      nationalFlag: 'is',
      startChar: 'I',
      telephoneCode: '354'
    },
    {
      chineseName: '',
      commonName: 'India',
      displayName: '印度',
      enable: true,
      formalName: '',
      id: 'IN',
      localName: '',
      nationalFlag: 'in',
      startChar: 'I',
      telephoneCode: '91'
    },
    {
      chineseName: '',
      commonName: 'Indonesia',
      displayName: '印度尼西亚',
      enable: true,
      formalName: '',
      id: 'ID',
      localName: '',
      nationalFlag: 'id',
      startChar: 'I',
      telephoneCode: '62'
    },
    {
      chineseName: '',
      commonName: 'Iran',
      displayName: '伊朗（伊斯兰共和国）',
      enable: true,
      formalName: '',
      id: 'IR',
      localName: '',
      nationalFlag: 'ir',
      startChar: 'I',
      telephoneCode: '98'
    },
    {
      chineseName: '',
      commonName: 'Iraq',
      displayName: '伊拉克',
      enable: true,
      formalName: '',
      id: 'IQ',
      localName: '',
      nationalFlag: 'iq',
      startChar: 'I',
      telephoneCode: '964'
    },
    {
      chineseName: '',
      commonName: 'Ireland',
      displayName: '爱尔兰',
      enable: true,
      formalName: '',
      id: 'IE',
      localName: '',
      nationalFlag: 'ie',
      startChar: 'I',
      telephoneCode: '353'
    },
    {
      chineseName: '',
      commonName: 'Israel',
      displayName: '以色列',
      enable: true,
      formalName: '',
      id: 'IL',
      localName: '',
      nationalFlag: 'il',
      startChar: 'I',
      telephoneCode: '972'
    },
    {
      chineseName: '',
      commonName: 'Italy',
      displayName: '意大利',
      enable: true,
      formalName: '',
      id: 'IT',
      localName: '',
      nationalFlag: 'it',
      startChar: 'I',
      telephoneCode: '39'
    }
  ],
  J: [
    {
      chineseName: '',
      commonName: 'Jamaica',
      displayName: '牙买加',
      enable: true,
      formalName: '',
      id: 'JM',
      localName: '',
      nationalFlag: 'jm',
      startChar: 'J',
      telephoneCode: '1876'
    },
    {
      chineseName: '',
      commonName: 'Japan',
      displayName: '日本',
      enable: true,
      formalName: '',
      id: 'JP',
      localName: '',
      nationalFlag: 'jp',
      startChar: 'J',
      telephoneCode: '81'
    },
    {
      chineseName: '',
      commonName: 'Jordan',
      displayName: '约旦',
      enable: true,
      formalName: '',
      id: 'JO',
      localName: '',
      nationalFlag: 'jo',
      startChar: 'J',
      telephoneCode: '962'
    }
  ],
  K: [
    {
      chineseName: '',
      commonName: 'Kazakhstan',
      displayName: '哈萨克斯坦',
      enable: true,
      formalName: '',
      id: 'KZ',
      localName: '',
      nationalFlag: 'kz',
      startChar: 'K',
      telephoneCode: '7'
    },
    {
      chineseName: '',
      commonName: 'Kenya',
      displayName: '肯尼亚',
      enable: true,
      formalName: '',
      id: 'KE',
      localName: '',
      nationalFlag: 'ke',
      startChar: 'K',
      telephoneCode: '254'
    },
    {
      chineseName: '',
      commonName: 'Kiribati',
      displayName: '基里巴斯',
      enable: true,
      formalName: '',
      id: 'KI',
      localName: '',
      nationalFlag: 'ki',
      startChar: 'K',
      telephoneCode: '686'
    },
    {
      chineseName: '',
      commonName: 'Korea, North',
      displayName: '朝鲜（朝鲜民主主义人民共和国）',
      enable: true,
      formalName: '',
      id: 'KP',
      localName: '',
      nationalFlag: 'kp',
      startChar: 'K',
      telephoneCode: '850'
    },
    {
      chineseName: '',
      commonName: 'Kosovo',
      displayName: '科索沃',
      enable: true,
      formalName: '',
      id: 'YK',
      localName: '',
      nationalFlag: 'xk',
      startChar: 'K',
      telephoneCode: '383'
    },
    {
      chineseName: '',
      commonName: 'Kuwait',
      displayName: '科威特',
      enable: true,
      formalName: '',
      id: 'KW',
      localName: '',
      nationalFlag: 'kw',
      startChar: 'K',
      telephoneCode: '965'
    },
    {
      chineseName: '',
      commonName: 'Kyrgyzstan',
      displayName: '吉尔吉斯斯坦',
      enable: true,
      formalName: '',
      id: 'KG',
      localName: '',
      nationalFlag: 'kg',
      startChar: 'K',
      telephoneCode: '996'
    }
  ],
  L: [
    {
      chineseName: '',
      commonName: 'Laos',
      displayName: '老挝人民民主共和国',
      enable: true,
      formalName: '',
      id: 'LA',
      localName: '',
      nationalFlag: 'la',
      startChar: 'L',
      telephoneCode: '856'
    },
    {
      chineseName: '',
      commonName: 'Latvia',
      displayName: '拉脱维亚',
      enable: true,
      formalName: '',
      id: 'LV',
      localName: '',
      nationalFlag: 'lv',
      startChar: 'L',
      telephoneCode: '371'
    },
    {
      chineseName: '',
      commonName: 'Lebanon',
      displayName: '黎巴嫩',
      enable: true,
      formalName: '',
      id: 'LB',
      localName: '',
      nationalFlag: 'lb',
      startChar: 'L',
      telephoneCode: '961'
    },
    {
      chineseName: '',
      commonName: 'Lesotho',
      displayName: '莱索托',
      enable: true,
      formalName: '',
      id: 'LS',
      localName: '',
      nationalFlag: 'ls',
      startChar: 'L',
      telephoneCode: '266'
    },
    {
      chineseName: '',
      commonName: 'Liberia',
      displayName: '利比里亚',
      enable: true,
      formalName: '',
      id: 'LR',
      localName: '',
      nationalFlag: 'lr',
      startChar: 'L',
      telephoneCode: '231'
    },
    {
      chineseName: '',
      commonName: 'Libya',
      displayName: '利比亚',
      enable: true,
      formalName: '',
      id: 'LY',
      localName: '',
      nationalFlag: 'ly',
      startChar: 'L',
      telephoneCode: '218'
    },
    {
      chineseName: '',
      commonName: 'Liechtenstein',
      displayName: '列支敦士登',
      enable: true,
      formalName: '',
      id: 'LI',
      localName: '',
      nationalFlag: 'li',
      startChar: 'L',
      telephoneCode: '423'
    },
    {
      chineseName: '',
      commonName: 'Lithuania',
      displayName: '立陶宛',
      enable: true,
      formalName: '',
      id: 'LT',
      localName: '',
      nationalFlag: 'lt',
      startChar: 'L',
      telephoneCode: '370'
    },
    {
      chineseName: '',
      commonName: 'Luxembourg',
      displayName: '卢森堡',
      enable: true,
      formalName: '',
      id: 'LU',
      localName: '',
      nationalFlag: 'lu',
      startChar: 'L',
      telephoneCode: '352'
    }
  ],
  M: [
    {
      chineseName: '',
      commonName: 'Macau',
      displayName: '澳门,中国',
      enable: true,
      formalName: '',
      id: 'MO',
      localName: '',
      nationalFlag: 'mo',
      startChar: 'M',
      telephoneCode: '853'
    },
    {
      chineseName: '',
      commonName: 'Macedonia',
      displayName: '马其顿共和国',
      enable: true,
      formalName: '',
      id: 'MK',
      localName: '',
      nationalFlag: 'mk',
      startChar: 'M',
      telephoneCode: '389'
    },
    {
      chineseName: '',
      commonName: 'Madagascar',
      displayName: '马达加斯加',
      enable: true,
      formalName: '',
      id: 'MG',
      localName: '',
      nationalFlag: 'mg',
      startChar: 'M',
      telephoneCode: '261'
    },
    {
      chineseName: '',
      commonName: 'Malawi',
      displayName: '马拉维',
      enable: true,
      formalName: '',
      id: 'MW',
      localName: '',
      nationalFlag: 'mw',
      startChar: 'M',
      telephoneCode: '265'
    },
    {
      chineseName: '',
      commonName: 'Malaysia',
      displayName: '马来西亚',
      enable: true,
      formalName: '',
      id: 'MY',
      localName: '',
      nationalFlag: 'my',
      startChar: 'M',
      telephoneCode: '60'
    },
    {
      chineseName: '',
      commonName: 'Maldives',
      displayName: '马尔代夫',
      enable: true,
      formalName: '',
      id: 'MV',
      localName: '',
      nationalFlag: 'mv',
      startChar: 'M',
      telephoneCode: '960'
    },
    {
      chineseName: '',
      commonName: 'Mali',
      displayName: '马里',
      enable: true,
      formalName: '',
      id: 'ML',
      localName: '',
      nationalFlag: 'ml',
      startChar: 'M',
      telephoneCode: '223'
    },
    {
      chineseName: '',
      commonName: 'Malta',
      displayName: '马耳他',
      enable: true,
      formalName: '',
      id: 'MT',
      localName: '',
      nationalFlag: 'mt',
      startChar: 'M',
      telephoneCode: '356'
    },
    {
      chineseName: '',
      commonName: 'Marshall Islands',
      displayName: '马绍尔群岛',
      enable: true,
      formalName: '',
      id: 'MH',
      localName: '',
      nationalFlag: 'mh',
      startChar: 'M',
      telephoneCode: '692'
    },
    {
      chineseName: '',
      commonName: 'Mauritania',
      displayName: '毛里塔尼亚',
      enable: true,
      formalName: '',
      id: 'MR',
      localName: '',
      nationalFlag: 'mr',
      startChar: 'M',
      telephoneCode: '222'
    },
    {
      chineseName: '',
      commonName: 'Mauritius',
      displayName: '毛里求斯',
      enable: true,
      formalName: '',
      id: 'MU',
      localName: '',
      nationalFlag: 'mu',
      startChar: 'M',
      telephoneCode: '230'
    },
    {
      chineseName: '',
      commonName: 'Mexico',
      displayName: '墨西哥',
      enable: true,
      formalName: '',
      id: 'MX',
      localName: '',
      nationalFlag: 'mx',
      startChar: 'M',
      telephoneCode: '52'
    },
    {
      chineseName: '',
      commonName: 'Micronesia',
      displayName: '密克罗尼西亚（联邦）',
      enable: true,
      formalName: '',
      id: 'FM',
      localName: '',
      nationalFlag: 'fm',
      startChar: 'M',
      telephoneCode: '691'
    },
    {
      chineseName: '',
      commonName: 'Moldova',
      displayName: '摩尔多瓦共和国',
      enable: true,
      formalName: '',
      id: 'MD',
      localName: '',
      nationalFlag: 'md',
      startChar: 'M',
      telephoneCode: '373'
    },
    {
      chineseName: '',
      commonName: 'Monaco',
      displayName: '摩纳哥',
      enable: true,
      formalName: '',
      id: 'MC',
      localName: '',
      nationalFlag: 'mc',
      startChar: 'M',
      telephoneCode: '377'
    },
    {
      chineseName: '',
      commonName: 'Mongolia',
      displayName: '蒙古',
      enable: true,
      formalName: '',
      id: 'MN',
      localName: '',
      nationalFlag: 'mn',
      startChar: 'M',
      telephoneCode: '976'
    },
    {
      chineseName: '',
      commonName: 'Montenegro',
      displayName: '黑山',
      enable: true,
      formalName: '',
      id: 'ME',
      localName: '',
      nationalFlag: 'me',
      startChar: 'M',
      telephoneCode: '382'
    },
    {
      chineseName: '',
      commonName: 'Morocco',
      displayName: '摩洛哥',
      enable: true,
      formalName: '',
      id: 'MA',
      localName: '',
      nationalFlag: 'ma',
      startChar: 'M',
      telephoneCode: '212'
    },
    {
      chineseName: '',
      commonName: 'Mozambique',
      displayName: '莫桑比克',
      enable: true,
      formalName: '',
      id: 'MZ',
      localName: '',
      nationalFlag: 'mz',
      startChar: 'M',
      telephoneCode: '258'
    },
    {
      chineseName: '',
      commonName: 'Myanmar (Burma)',
      displayName: '缅甸',
      enable: true,
      formalName: '',
      id: 'MM',
      localName: '',
      nationalFlag: 'mm',
      startChar: 'M',
      telephoneCode: '95'
    }
  ],
  N: [
    {
      chineseName: '',
      commonName: 'Namibia',
      displayName: '纳米比亚',
      enable: true,
      formalName: '',
      id: 'NA',
      localName: '',
      nationalFlag: 'na',
      startChar: 'N',
      telephoneCode: '264'
    },
    {
      chineseName: '',
      commonName: 'Nauru',
      displayName: '瑙鲁',
      enable: true,
      formalName: '',
      id: 'NR',
      localName: '',
      nationalFlag: 'nr',
      startChar: 'N',
      telephoneCode: '674'
    },
    {
      chineseName: '',
      commonName: 'Nepal',
      displayName: '尼泊尔',
      enable: true,
      formalName: '',
      id: 'NP',
      localName: '',
      nationalFlag: 'np',
      startChar: 'N',
      telephoneCode: '977'
    },
    {
      chineseName: '',
      commonName: 'Netherlands',
      displayName: '荷兰',
      enable: true,
      formalName: '',
      id: 'NL',
      localName: '',
      nationalFlag: 'nl',
      startChar: 'N',
      telephoneCode: '31'
    },
    {
      chineseName: '',
      commonName: 'New Caledonia',
      displayName: '新喀里多尼亚',
      enable: true,
      formalName: '',
      id: 'NC',
      localName: '',
      nationalFlag: 'nc',
      startChar: 'N',
      telephoneCode: '687'
    },
    {
      chineseName: '',
      commonName: 'New Zealand',
      displayName: '新西兰',
      enable: true,
      formalName: '',
      id: 'NZ',
      localName: '',
      nationalFlag: 'nz',
      startChar: 'N',
      telephoneCode: '64'
    },
    {
      chineseName: '',
      commonName: 'Nicaragua',
      displayName: '尼加拉瓜',
      enable: true,
      formalName: '',
      id: 'NI',
      localName: '',
      nationalFlag: 'ni',
      startChar: 'N',
      telephoneCode: '505'
    },
    {
      chineseName: '',
      commonName: 'Niger',
      displayName: '尼日尔',
      enable: true,
      formalName: '',
      id: 'NE',
      localName: '',
      nationalFlag: 'ne',
      startChar: 'N',
      telephoneCode: '227'
    },
    {
      chineseName: '',
      commonName: 'Nigeria',
      displayName: '尼日利亚',
      enable: true,
      formalName: '',
      id: 'NG',
      localName: '',
      nationalFlag: 'ng',
      startChar: 'N',
      telephoneCode: '234'
    },
    {
      chineseName: '',
      commonName: 'Norway',
      displayName: '挪威',
      enable: true,
      formalName: '',
      id: 'NO',
      localName: '',
      nationalFlag: 'no',
      startChar: 'N',
      telephoneCode: '47'
    }
  ],
  O: [
    {
      chineseName: '',
      commonName: 'Oman',
      displayName: '阿曼',
      enable: true,
      formalName: '',
      id: 'OM',
      localName: '',
      nationalFlag: 'om',
      startChar: 'O',
      telephoneCode: '968'
    }
  ],
  P: [
    {
      chineseName: '',
      commonName: 'Pakistan',
      displayName: '巴基斯坦',
      enable: true,
      formalName: '',
      id: 'PK',
      localName: '',
      nationalFlag: 'pk',
      startChar: 'P',
      telephoneCode: '92'
    },
    {
      chineseName: '',
      commonName: 'Palau',
      displayName: '帛琉',
      enable: true,
      formalName: '',
      id: 'PW',
      localName: '',
      nationalFlag: 'pw',
      startChar: 'P',
      telephoneCode: '680'
    },
    {
      chineseName: '',
      commonName: 'Palestine',
      displayName: '巴勒斯坦',
      enable: true,
      formalName: '',
      id: 'PS',
      localName: '',
      nationalFlag: 'ps',
      startChar: 'P',
      telephoneCode: '970'
    },
    {
      chineseName: '',
      commonName: 'Panama',
      displayName: '巴拿马',
      enable: true,
      formalName: '',
      id: 'PA',
      localName: '',
      nationalFlag: 'pa',
      startChar: 'P',
      telephoneCode: '507'
    },
    {
      chineseName: '',
      commonName: 'Papua New Guinea',
      displayName: '巴布亚新几内亚',
      enable: true,
      formalName: '',
      id: 'PG',
      localName: '',
      nationalFlag: 'pg',
      startChar: 'P',
      telephoneCode: '675'
    },
    {
      chineseName: '',
      commonName: 'Paraguay',
      displayName: '巴拉圭',
      enable: true,
      formalName: '',
      id: 'PY',
      localName: '',
      nationalFlag: 'py',
      startChar: 'P',
      telephoneCode: '595'
    },
    {
      chineseName: '',
      commonName: 'Peru',
      displayName: '秘鲁',
      enable: true,
      formalName: '',
      id: 'PE',
      localName: '',
      nationalFlag: 'pe',
      startChar: 'P',
      telephoneCode: '51'
    },
    {
      chineseName: '',
      commonName: 'Philippines',
      displayName: '菲律宾',
      enable: true,
      formalName: '',
      id: 'PH',
      localName: '',
      nationalFlag: 'ph',
      startChar: 'P',
      telephoneCode: '63'
    },
    {
      chineseName: '',
      commonName: 'Poland',
      displayName: '波兰',
      enable: true,
      formalName: '',
      id: 'PL',
      localName: '',
      nationalFlag: 'pl',
      startChar: 'P',
      telephoneCode: '48'
    },
    {
      chineseName: '',
      commonName: 'Portugal',
      displayName: '葡萄牙',
      enable: true,
      formalName: '',
      id: 'PT',
      localName: '',
      nationalFlag: 'pt',
      startChar: 'P',
      telephoneCode: '351'
    }
  ],
  Q: [
    {
      chineseName: '',
      commonName: 'Qatar',
      displayName: '卡塔尔',
      enable: true,
      formalName: '',
      id: 'QA',
      localName: '',
      nationalFlag: 'qa',
      startChar: 'Q',
      telephoneCode: '974'
    }
  ],
  R: [
    {
      chineseName: '',
      commonName: 'Reunion',
      displayName: '留尼旺岛',
      enable: true,
      formalName: '',
      id: 'RE',
      localName: '',
      nationalFlag: 're',
      startChar: 'R',
      telephoneCode: '262'
    },
    {
      chineseName: '',
      commonName: 'Romania',
      displayName: '罗马尼亚',
      enable: true,
      formalName: '',
      id: 'RO',
      localName: '',
      nationalFlag: 'ro',
      startChar: 'R',
      telephoneCode: '40'
    },
    {
      chineseName: '',
      commonName: 'Russia',
      displayName: '俄罗斯',
      enable: true,
      formalName: '',
      id: 'RU',
      localName: '',
      nationalFlag: 'ru',
      startChar: 'R',
      telephoneCode: '7'
    },
    {
      chineseName: '',
      commonName: 'Rwanda',
      displayName: '卢旺达',
      enable: true,
      formalName: '',
      id: 'RW',
      localName: '',
      nationalFlag: 'rw',
      startChar: 'R',
      telephoneCode: '250'
    }
  ],
  S: [
    {
      chineseName: '',
      commonName: 'Saint Kitts and Nevis',
      displayName: '圣基茨和尼维斯',
      enable: true,
      formalName: '',
      id: 'KN',
      localName: '',
      nationalFlag: 'kn',
      startChar: 'S',
      telephoneCode: '1869'
    },
    {
      chineseName: '',
      commonName: 'Saint Lucia',
      displayName: '圣卢西亚',
      enable: true,
      formalName: '',
      id: 'LC',
      localName: '',
      nationalFlag: 'lc',
      startChar: 'S',
      telephoneCode: '1758'
    },
    {
      chineseName: '',
      commonName: 'Saint Vincent and the Grenadines',
      displayName: '圣文森特和格林纳丁斯',
      enable: true,
      formalName: '',
      id: 'VC',
      localName: '',
      nationalFlag: 'vc',
      startChar: 'S',
      telephoneCode: '1784'
    },
    {
      chineseName: '',
      commonName: 'Samoa',
      displayName: '萨摩亚',
      enable: true,
      formalName: '',
      id: 'WS',
      localName: '',
      nationalFlag: 'ws',
      startChar: 'S',
      telephoneCode: '685'
    },
    {
      chineseName: '',
      commonName: 'San Marino',
      displayName: '圣马力诺',
      enable: true,
      formalName: '',
      id: 'SM',
      localName: '',
      nationalFlag: 'sm',
      startChar: 'S',
      telephoneCode: '378'
    },
    {
      chineseName: '',
      commonName: 'Sao Tome and Principe',
      displayName: '圣多美和普林西比',
      enable: true,
      formalName: '',
      id: 'ST',
      localName: '',
      nationalFlag: 'st',
      startChar: 'S',
      telephoneCode: '239'
    },
    {
      chineseName: '',
      commonName: 'Saudi Arabia',
      displayName: '沙特阿拉伯',
      enable: true,
      formalName: '',
      id: 'SA',
      localName: '',
      nationalFlag: 'sa',
      startChar: 'S',
      telephoneCode: '966'
    },
    {
      chineseName: '',
      commonName: 'Senegal',
      displayName: '塞内加尔',
      enable: true,
      formalName: '',
      id: 'SN',
      localName: '',
      nationalFlag: 'sn',
      startChar: 'S',
      telephoneCode: '221'
    },
    {
      chineseName: '',
      commonName: 'Serbia',
      displayName: '塞尔维亚',
      enable: true,
      formalName: '',
      id: 'RS',
      localName: '',
      nationalFlag: 'rs',
      startChar: 'S',
      telephoneCode: '381'
    },
    {
      chineseName: '',
      commonName: 'Seychelles',
      displayName: '塞舌尔',
      enable: true,
      formalName: '',
      id: 'SC',
      localName: '',
      nationalFlag: 'sc',
      startChar: 'S',
      telephoneCode: '248'
    },
    {
      chineseName: '',
      commonName: 'Sierra Leone',
      displayName: '塞拉利昂',
      enable: true,
      formalName: '',
      id: 'SL',
      localName: '',
      nationalFlag: 'sl',
      startChar: 'S',
      telephoneCode: '232'
    },
    {
      chineseName: '',
      commonName: 'Singapore',
      displayName: '新加坡',
      enable: true,
      formalName: '',
      id: 'SG',
      localName: '',
      nationalFlag: 'sg',
      startChar: 'S',
      telephoneCode: '65'
    },
    {
      chineseName: '',
      commonName: 'Slovakia',
      displayName: '斯洛伐克',
      enable: true,
      formalName: '',
      id: 'SK',
      localName: '',
      nationalFlag: 'sk',
      startChar: 'S',
      telephoneCode: '421'
    },
    {
      chineseName: '',
      commonName: 'Slovenia',
      displayName: '斯洛文尼亚',
      enable: true,
      formalName: '',
      id: 'SI',
      localName: '',
      nationalFlag: 'si',
      startChar: 'S',
      telephoneCode: '386'
    },
    {
      chineseName: '',
      commonName: 'Solomon Islands',
      displayName: '所罗门群岛',
      enable: true,
      formalName: '',
      id: 'SB',
      localName: '',
      nationalFlag: 'sb',
      startChar: 'S',
      telephoneCode: '677'
    },
    {
      chineseName: '',
      commonName: 'Somalia',
      displayName: '索马里',
      enable: true,
      formalName: '',
      id: 'SO',
      localName: '',
      nationalFlag: 'so',
      startChar: 'S',
      telephoneCode: '252'
    },
    {
      chineseName: '',
      commonName: 'South Africa',
      displayName: '南非',
      enable: true,
      formalName: '',
      id: 'ZA',
      localName: '',
      nationalFlag: 'za',
      startChar: 'S',
      telephoneCode: '27'
    },
    {
      chineseName: '',
      commonName: 'South Korea',
      displayName: '韩国',
      enable: true,
      formalName: '',
      id: 'KR',
      localName: '',
      nationalFlag: 'kr',
      startChar: 'S',
      telephoneCode: '82'
    },
    {
      chineseName: '',
      commonName: 'South Sudan',
      displayName: '南苏丹',
      enable: true,
      formalName: '',
      id: 'SS',
      localName: '',
      nationalFlag: 'ss',
      startChar: 'S',
      telephoneCode: '211'
    },
    {
      chineseName: '',
      commonName: 'Spain',
      displayName: '西班牙',
      enable: true,
      formalName: '',
      id: 'ES',
      localName: '',
      nationalFlag: 'es',
      startChar: 'S',
      telephoneCode: '34'
    },
    {
      chineseName: '',
      commonName: 'Sri Lanka',
      displayName: '斯里兰卡',
      enable: true,
      formalName: '',
      id: 'LK',
      localName: '',
      nationalFlag: 'lk',
      startChar: 'S',
      telephoneCode: '94'
    },
    {
      chineseName: '',
      commonName: 'Sudan',
      displayName: '苏丹',
      enable: true,
      formalName: '',
      id: 'SD',
      localName: '',
      nationalFlag: 'sd',
      startChar: 'S',
      telephoneCode: '249'
    },
    {
      chineseName: '',
      commonName: 'Suriname',
      displayName: '苏里南',
      enable: true,
      formalName: '',
      id: 'SR',
      localName: '',
      nationalFlag: 'sr',
      startChar: 'S',
      telephoneCode: '597'
    },
    {
      chineseName: '',
      commonName: 'Swaziland',
      displayName: '斯威士兰',
      enable: true,
      formalName: '',
      id: 'SZ',
      localName: '',
      nationalFlag: 'sz',
      startChar: 'S',
      telephoneCode: '268'
    },
    {
      chineseName: '',
      commonName: 'Sweden',
      displayName: '瑞典',
      enable: true,
      formalName: '',
      id: 'SE',
      localName: '',
      nationalFlag: 'se',
      startChar: 'S',
      telephoneCode: '46'
    },
    {
      chineseName: '',
      commonName: 'Switzerland',
      displayName: '瑞士',
      enable: true,
      formalName: '',
      id: 'CH',
      localName: '',
      nationalFlag: 'ch',
      startChar: 'S',
      telephoneCode: '41'
    },
    {
      chineseName: '',
      commonName: 'Syria',
      displayName: '阿拉伯叙利亚共和国',
      enable: true,
      formalName: '',
      id: 'SY',
      localName: '',
      nationalFlag: 'sy',
      startChar: 'S',
      telephoneCode: '963'
    }
  ],
  T: [
    {
      chineseName: '',
      commonName: 'Taiwan',
      displayName: '台湾,中国',
      enable: true,
      formalName: '',
      id: 'TW',
      localName: '',
      nationalFlag: 'tw',
      startChar: 'T',
      telephoneCode: '886'
    },
    {
      chineseName: '',
      commonName: 'Tajikistan',
      displayName: '塔吉克斯坦',
      enable: true,
      formalName: '',
      id: 'TJ',
      localName: '',
      nationalFlag: 'tj',
      startChar: 'T',
      telephoneCode: '992'
    },
    {
      chineseName: '',
      commonName: 'Tanzania',
      displayName: '坦桑尼亚联合共和国',
      enable: true,
      formalName: '',
      id: 'TZ',
      localName: '',
      nationalFlag: 'tz',
      startChar: 'T',
      telephoneCode: '255'
    },
    {
      chineseName: '',
      commonName: 'Thailand',
      displayName: '泰国',
      enable: true,
      formalName: '',
      id: 'TH',
      localName: '',
      nationalFlag: 'th',
      startChar: 'T',
      telephoneCode: '66'
    },
    {
      chineseName: '',
      commonName: 'The British Overseas Territory of Gibraltar',
      displayName: '直布罗陀',
      enable: true,
      formalName: '',
      id: 'GI',
      localName: '',
      nationalFlag: 'gi',
      startChar: 'T',
      telephoneCode: '350'
    },
    {
      chineseName: '',
      commonName: 'The Cook Islands',
      displayName: '库克群岛',
      enable: true,
      formalName: '',
      id: 'CK',
      localName: '',
      nationalFlag: 'ck',
      startChar: 'T',
      telephoneCode: '682'
    },
    {
      chineseName: '',
      commonName: 'Timor-Leste (East Timor)',
      displayName: '东帝汶',
      enable: true,
      formalName: '',
      id: 'TL',
      localName: '',
      nationalFlag: 'tl',
      startChar: 'T',
      telephoneCode: '670'
    },
    {
      chineseName: '',
      commonName: 'Togo',
      displayName: '多哥',
      enable: true,
      formalName: '',
      id: 'TG',
      localName: '',
      nationalFlag: 'tg',
      startChar: 'T',
      telephoneCode: '228'
    },
    {
      chineseName: '',
      commonName: 'Tonga',
      displayName: '汤加',
      enable: true,
      formalName: '',
      id: 'TO',
      localName: '',
      nationalFlag: 'to',
      startChar: 'T',
      telephoneCode: '676'
    },
    {
      chineseName: '',
      commonName: 'Trinidad and Tobago',
      displayName: '特立尼达和多巴哥',
      enable: true,
      formalName: '',
      id: 'TT',
      localName: '',
      nationalFlag: 'tt',
      startChar: 'T',
      telephoneCode: '1868'
    },
    {
      chineseName: '',
      commonName: 'Tunisia',
      displayName: '突尼斯',
      enable: true,
      formalName: '',
      id: 'TN',
      localName: '',
      nationalFlag: 'tn',
      startChar: 'T',
      telephoneCode: '216'
    },
    {
      chineseName: '',
      commonName: 'Turkey',
      displayName: '土耳其',
      enable: true,
      formalName: '',
      id: 'TR',
      localName: '',
      nationalFlag: 'tr',
      startChar: 'T',
      telephoneCode: '90'
    },
    {
      chineseName: '',
      commonName: 'Turkmenistan',
      displayName: '土库曼斯坦',
      enable: true,
      formalName: '',
      id: 'TM',
      localName: '',
      nationalFlag: 'tm',
      startChar: 'T',
      telephoneCode: '993'
    },
    {
      chineseName: '',
      commonName: 'Tuvalu',
      displayName: '图瓦卢',
      enable: true,
      formalName: '',
      id: 'TV',
      localName: '',
      nationalFlag: 'tv',
      startChar: 'T',
      telephoneCode: '688'
    }
  ],
  U: [
    {
      chineseName: '',
      commonName: 'U.S. Virgin Islands',
      displayName: '维尔京群岛（美属）',
      enable: true,
      formalName: '',
      id: 'VI',
      localName: '',
      nationalFlag: 'vi',
      startChar: 'U',
      telephoneCode: '1340'
    },
    {
      chineseName: '',
      commonName: 'Uganda',
      displayName: '乌干达',
      enable: true,
      formalName: '',
      id: 'UG',
      localName: '',
      nationalFlag: 'ug',
      startChar: 'U',
      telephoneCode: '256'
    },
    {
      chineseName: '',
      commonName: 'Ukraine',
      displayName: '乌克兰',
      enable: true,
      formalName: '',
      id: 'UA',
      localName: '',
      nationalFlag: 'ua',
      startChar: 'U',
      telephoneCode: '380'
    },
    {
      chineseName: '',
      commonName: 'United Arab Emirates',
      displayName: '阿联酋',
      enable: true,
      formalName: '',
      id: 'AE',
      localName: '',
      nationalFlag: 'ae',
      startChar: 'U',
      telephoneCode: '971'
    },
    {
      chineseName: '',
      commonName: 'United Kingdom',
      displayName: '英国',
      enable: true,
      formalName: '',
      id: 'GB',
      localName: '',
      nationalFlag: 'gb',
      startChar: 'U',
      telephoneCode: '44'
    },
    {
      chineseName: '',
      commonName: 'United States',
      displayName: '美国',
      enable: true,
      formalName: '',
      id: 'US',
      localName: '',
      nationalFlag: 'us',
      startChar: 'U',
      telephoneCode: '1'
    },
    {
      chineseName: '',
      commonName: 'Uruguay',
      displayName: '乌拉圭',
      enable: true,
      formalName: '',
      id: 'UY',
      localName: '',
      nationalFlag: 'uy',
      startChar: 'U',
      telephoneCode: '598'
    },
    {
      chineseName: '',
      commonName: 'Uzbekistan',
      displayName: '乌兹别克斯坦',
      enable: true,
      formalName: '',
      id: 'UZ',
      localName: '',
      nationalFlag: 'uz',
      startChar: 'U',
      telephoneCode: '998'
    }
  ],
  V: [
    {
      chineseName: '',
      commonName: 'Vanuatu',
      displayName: '瓦努阿图',
      enable: true,
      formalName: '',
      id: 'VU',
      localName: '',
      nationalFlag: 'vu',
      startChar: 'V',
      telephoneCode: '678'
    },
    {
      chineseName: '',
      commonName: 'Vatican City',
      displayName: '罗马教廷',
      enable: true,
      formalName: '',
      id: 'VA',
      localName: '',
      nationalFlag: 'va',
      startChar: 'V',
      telephoneCode: '379'
    },
    {
      chineseName: '',
      commonName: 'Venezuela',
      displayName: '委内瑞拉玻利瓦尔共和国',
      enable: true,
      formalName: '',
      id: 'VE',
      localName: '',
      nationalFlag: 've',
      startChar: 'V',
      telephoneCode: '58'
    },
    {
      chineseName: '',
      commonName: 'Vietnam',
      displayName: '越南',
      enable: true,
      formalName: '',
      id: 'VN',
      localName: '',
      nationalFlag: 'vn',
      startChar: 'V',
      telephoneCode: '84'
    }
  ],
  Y: [
    {
      chineseName: '',
      commonName: 'Yemen',
      displayName: '也门',
      enable: true,
      formalName: '',
      id: 'YE',
      localName: '',
      nationalFlag: 'ye',
      startChar: 'Y',
      telephoneCode: '967'
    }
  ],
  Z: [
    {
      chineseName: '',
      commonName: 'Zambia',
      displayName: '赞比亚',
      enable: true,
      formalName: '',
      id: 'ZM',
      localName: '',
      nationalFlag: 'zm',
      startChar: 'Z',
      telephoneCode: '260'
    },
    {
      chineseName: '',
      commonName: 'Zimbabwe',
      displayName: '津巴布韦',
      enable: true,
      formalName: '',
      id: 'ZW',
      localName: '',
      nationalFlag: 'zw',
      startChar: 'Z',
      telephoneCode: '263'
    }
  ]
}

export default Object.values(countries).flat()
